import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { errorMock, useGetShoppingCartSilentUpdate, useValidateVoucher, useVoucher } from "@vfit/consumer/data-access";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { useCheckout } from "../../../iBuyMobile.context";
import { DEFAULT_VOUCHER_DISCOUNT } from "../../../iBuyMobile.context.data";
export var useVoucherDiscount = function() {
    var ref = useState(""), cartId = ref[0], setCartId = ref[1];
    var ref1 = useCheckout(), voucher = ref1.voucher, setVoucher = ref1.setVoucher;
    var voucherId = voucher.voucherId, isApplyVoucher = voucher.isApplyVoucher;
    var VALID_VOUCHER_CODE = "0";
    var EXPIRED_VOUCHER_CODE = "1002";
    var ref2 = useGetShoppingCartSilentUpdate(cartId, {
        enabled: false,
        keyDependency: [
            "getConfigVoucherShoppingCart"
        ]
    }), configShoppingCartResponse = ref2.data, isSuccessConfigShoppingCart = ref2.isSuccess, isLoadingConfigShoppingCart = ref2.isLoading, isErrorConfigShoppingCart = ref2.isError, errorConfigShoppingCart = ref2.error, refetchConfigShoppingCart = ref2.refetch;
    var ref3 = useValidateVoucher(), validateVoucher = ref3.data, validateVoucherMutate = ref3.mutateAsync, isLoadingValidateVoucher = ref3.isLoading, isSuccessValidateVoucher = ref3.isSuccess, isErrorValidateVoucher = ref3.isError, errorValidateVoucher = ref3.error;
    var ref4 = useVoucher(), voucherResponse = ref4.data, voucherMutate = ref4.mutateAsync, isLoadingVoucher = ref4.isLoading, isSuccessVoucher = ref4.isSuccess, isErrorVoucher = ref4.isError, errorVoucher = ref4.error;
    var ref5 = useGetShoppingCartSilentUpdate(cartId, {
        enabled: false,
        keyDependency: [
            "getVoucherShoppingCart"
        ]
    }), shoppingCartResponse = ref5.data, isSuccessShoppingCart = ref5.isSuccess, isLoadingShoppingCart = ref5.isLoading, isErrorShoppingCart = ref5.isError, errorShoppingCart = ref5.error, refetchShoppingCart = ref5.refetch;
    // region Apply Voucher
    useEffect(function() {
        if (isLoadingShoppingCart) {
            setVoucher(_object_spread_props(_object_spread({}, voucher), {
                shoppingCart: _object_spread_props(_object_spread({}, voucher.shoppingCart), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isErrorShoppingCart) {
            setVoucher(_object_spread_props(_object_spread({}, voucher), {
                shoppingCart: _object_spread_props(_object_spread({}, voucher.shoppingCart), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("shoppingCart", errorShoppingCart)
                })
            }));
        } else if (isSuccessShoppingCart) {
            setVoucher(_object_spread_props(_object_spread({}, voucher), {
                isAppliedVoucher: true,
                shoppingCart: _object_spread_props(_object_spread({}, voucher.shoppingCart), {
                    data: shoppingCartResponse,
                    isLoading: false,
                    isSuccess: true,
                    isError: false
                })
            }));
        }
    }, [
        isSuccessShoppingCart,
        isLoadingShoppingCart,
        isErrorShoppingCart
    ]);
    useEffect(function() {
        if (isLoadingVoucher) {
            setVoucher(_object_spread_props(_object_spread({}, voucher), {
                voucher: {
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                }
            }));
        } else if (isErrorVoucher) {
            setVoucher(_object_spread_props(_object_spread({}, voucher), {
                voucher: {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("voucher", errorVoucher)
                }
            }));
        } else if (isSuccessVoucher) {
            setVoucher(_object_spread_props(_object_spread({}, voucher), {
                shoppingCart: {
                    isSuccess: false,
                    isError: false,
                    isLoading: true
                },
                voucher: {
                    data: voucherResponse,
                    isLoading: false,
                    isSuccess: true,
                    isError: false
                }
            }));
            refetchShoppingCart();
        }
    }, [
        isSuccessVoucher,
        isLoadingVoucher,
        isErrorVoucher
    ]);
    useEffect(function() {
        var ref;
        if (isApplyVoucher && (voucher === null || voucher === void 0 ? void 0 : (ref = voucher.validateVoucher) === null || ref === void 0 ? void 0 : ref.data)) {
            var ref1, ref2, ref3, ref4, ref5, ref6, ref7;
            setVoucher(_object_spread_props(_object_spread({}, voucher), {
                isApplyVoucher: false
            }));
            var customerData = getFromLocalStorageByKey("customerData");
            voucherMutate({
                voucherId: voucher === null || voucher === void 0 ? void 0 : (ref1 = voucher.validateVoucher) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.data) === null || ref2 === void 0 ? void 0 : ref2.voucherId,
                voucherStartDate: voucher === null || voucher === void 0 ? void 0 : (ref3 = voucher.validateVoucher) === null || ref3 === void 0 ? void 0 : ref3.data.startDate,
                voucherEndDate: voucher === null || voucher === void 0 ? void 0 : (ref4 = voucher.validateVoucher) === null || ref4 === void 0 ? void 0 : ref4.data.endDate,
                benefitBOCID: voucher === null || voucher === void 0 ? void 0 : (ref5 = voucher.validateVoucher) === null || ref5 === void 0 ? void 0 : ref5.data.benefitBoCID,
                parentBOId: voucher === null || voucher === void 0 ? void 0 : (ref6 = voucher.validateVoucher) === null || ref6 === void 0 ? void 0 : ref6.data.parentBoId,
                operationType: "addVoucher",
                customerId: (customerData === null || customerData === void 0 ? void 0 : (ref7 = customerData[0]) === null || ref7 === void 0 ? void 0 : ref7.id) || "",
                isShortFlow: false
            });
        }
    }, [
        isApplyVoucher
    ]);
    // endregion
    // region Validate voucher
    var callValidateVoucher = function() {
        if (voucherId) {
            var shoppingCart = getFromLocalStorageByKey("shoppingCart");
            setCartId(shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id);
            validateVoucherMutate({
                voucherId: voucherId
            });
        }
    };
    useEffect(function() {
        if (isLoadingValidateVoucher) {
            setVoucher(_object_spread_props(_object_spread({}, voucher), {
                resetData: resetData,
                isExpiredVoucher: false,
                isInvalidVoucher: false,
                validateVoucher: {
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                }
            }));
        } else if (isErrorValidateVoucher) {
            setVoucher(_object_spread_props(_object_spread({}, voucher), {
                resetData: resetData,
                isExpiredVoucher: false,
                isInvalidVoucher: false,
                validateVoucher: {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("validateVoucher", errorValidateVoucher)
                }
            }));
        } else if (isSuccessValidateVoucher) {
            var isExpiredVoucher = (validateVoucher === null || validateVoucher === void 0 ? void 0 : validateVoucher.returnedCode) == EXPIRED_VOUCHER_CODE;
            var isInvalidVoucher = (validateVoucher === null || validateVoucher === void 0 ? void 0 : validateVoucher.returnedCode) != VALID_VOUCHER_CODE;
            setVoucher(_object_spread_props(_object_spread({}, voucher), {
                resetData: resetData,
                isInvalidVoucher: isInvalidVoucher,
                isExpiredVoucher: isExpiredVoucher,
                validateVoucher: {
                    data: validateVoucher,
                    isLoading: false,
                    isSuccess: true,
                    isError: false
                }
            }));
        }
    }, [
        isSuccessValidateVoucher,
        isLoadingValidateVoucher,
        isErrorValidateVoucher
    ]);
    useEffect(function() {
        if (isLoadingConfigShoppingCart) {
            setVoucher(_object_spread_props(_object_spread({}, voucher), {
                validateVoucher: {
                    isError: false,
                    isSuccess: false,
                    isLoading: true
                },
                configShoppingCart: {
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                }
            }));
        } else if (isErrorConfigShoppingCart) {
            setVoucher(_object_spread_props(_object_spread({}, voucher), {
                validateVoucher: {
                    isError: false,
                    isSuccess: false,
                    isLoading: false
                },
                configShoppingCart: {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("configShoppingCart", errorConfigShoppingCart)
                }
            }));
        } else if (isSuccessConfigShoppingCart) {
            setVoucher(_object_spread_props(_object_spread({}, voucher), {
                validateVoucher: {
                    isError: false,
                    isSuccess: false,
                    isLoading: true
                },
                configShoppingCart: {
                    data: configShoppingCartResponse,
                    isLoading: false,
                    isSuccess: true,
                    isError: false
                }
            }));
            callValidateVoucher();
        }
    }, [
        isSuccessConfigShoppingCart,
        isLoadingConfigShoppingCart,
        isErrorConfigShoppingCart
    ]);
    useEffect(function() {
        if (voucherId) {
            var ref;
            if (voucher === null || voucher === void 0 ? void 0 : (ref = voucher.configShoppingCart) === null || ref === void 0 ? void 0 : ref.isSuccess) {
                callValidateVoucher();
            } else {
                refetchConfigShoppingCart();
            }
        }
    }, [
        voucherId
    ]);
    // endregion
    // region Reset
    var resetData = function() {
        setVoucher(_object_spread_props(_object_spread({
            configShoppingCart: (voucher === null || voucher === void 0 ? void 0 : voucher.configShoppingCart) || {
                isError: false,
                isSuccess: false,
                isLoading: false,
                data: undefined,
                error: undefined
            }
        }, DEFAULT_VOUCHER_DISCOUNT), {
            resetData: resetData
        }));
    };
    useEffect(function() {
        setVoucher(_object_spread_props(_object_spread({}, voucher), {
            resetData: resetData
        }));
    }, []);
    // endregion
    return null;
};
