import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { ErrorCodes, useCheckPortInFeasibilityIIFixed, useModifyPhoneNumberFixedProduct } from "@vfit/consumer/data-access";
import { useCheckout } from "../../../iBuyFixed.context";
export var usePortabilityFlow = function() {
    var ref = useCheckout(), portability = ref.portability, setPortability = ref.setPortability;
    var ref1 = useState(""), modifiedNumber = ref1[0], setModifiedNumber = ref1[1];
    var ref2 = useCheckPortInFeasibilityIIFixed(), mutatePortIn = ref2.mutate, dataPortIn = ref2.data, isErrorPortIn = ref2.isError, isLoadingPortIn = ref2.isLoading, isSuccessPortIn = ref2.isSuccess, resetPortIn = ref2.reset;
    var ref3 = useModifyPhoneNumberFixedProduct(), mutateModify = ref3.mutate, isErrorModify = ref3.isError, isSuccessModify = ref3.isSuccess;
    useEffect(function() {
        if ((portability === null || portability === void 0 ? void 0 : portability.isStartPortability) && (portability === null || portability === void 0 ? void 0 : portability.portabilityNumber) !== modifiedNumber) {
            var ref;
            setPortability(_object_spread_props(_object_spread({}, portability), {
                isStartPortability: false,
                isLoading: true,
                isError: false,
                isSuccess: false
            }));
            mutatePortIn(_object_spread_props(_object_spread({}, portability), {
                isMultipleOperator: (portability === null || portability === void 0 ? void 0 : portability.portabilityOperators) === "double" || false,
                hasInternetService: (portability === null || portability === void 0 ? void 0 : (ref = portability.portabilityInfo) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "telint"
            }));
        }
    }, [
        portability
    ]);
    useEffect(function() {
        var ref, ref1, ref2, ref3, ref4;
        if (isSuccessPortIn && dataPortIn && !isErrorPortIn && ((ref = dataPortIn.resultDescription) === null || ref === void 0 ? void 0 : ref.toLowerCase()) !== "failure" && !((ref1 = dataPortIn) === null || ref1 === void 0 ? void 0 : ref1.error)) {
            var ref5;
            mutateModify(_object_spread_props(_object_spread({}, portability), {
                isMultipleOperator: (portability === null || portability === void 0 ? void 0 : portability.portabilityOperators) === "double" || false,
                hasInternetService: (portability === null || portability === void 0 ? void 0 : (ref5 = portability.portabilityInfo) === null || ref5 === void 0 ? void 0 : ref5.toLowerCase()) === "telint"
            }));
        }
        if ((ref2 = dataPortIn) === null || ref2 === void 0 ? void 0 : ref2.error) {
            var ref6;
            setPortability(_object_spread_props(_object_spread({}, portability), {
                isLoading: false,
                isSuccess: false,
                isError: true,
                errorMessage: (ref6 = dataPortIn) === null || ref6 === void 0 ? void 0 : ref6.errorMessage
            }));
        }
        if (isSuccessPortIn && (!dataPortIn || ((ref3 = dataPortIn) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.resultDescription) === null || ref4 === void 0 ? void 0 : ref4.toLowerCase()) === "failure")) {
            var ref7, ref8, ref9, ref10, ref11, ref12;
            resetPortIn();
            var errorText = "";
            if (((ref7 = dataPortIn) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.resultMessages) === null || ref8 === void 0 ? void 0 : (ref9 = ref8[0]) === null || ref9 === void 0 ? void 0 : ref9.messageCode) === "9000054") {
                errorText = ErrorCodes.MIGRATION_CODE_ERROR;
            } else if ((ref10 = dataPortIn) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.resultMessages) === null || ref11 === void 0 ? void 0 : (ref12 = ref11[0]) === null || ref12 === void 0 ? void 0 : ref12.messageDescription) {
                errorText = ErrorCodes.NUMBER_ERROR;
            } else {
                errorText = ErrorCodes.CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR;
            }
            setPortability(_object_spread_props(_object_spread({}, portability), {
                isLoading: false,
                isSuccess: false,
                isError: true,
                errorMessage: errorText
            }));
        }
    }, [
        dataPortIn,
        isLoadingPortIn,
        isSuccessPortIn,
        isErrorPortIn
    ]);
    useEffect(function() {
        if (isSuccessModify) {
            setModifiedNumber(portability === null || portability === void 0 ? void 0 : portability.portabilityNumber);
            setPortability(_object_spread_props(_object_spread({}, portability), {
                isLoading: false,
                isError: false,
                isSuccess: true
            }));
        }
        if (isErrorModify) {
            setPortability(_object_spread_props(_object_spread({}, portability), {
                isLoading: false,
                isSuccess: false,
                isError: true
            }));
        }
    }, [
        isSuccessModify,
        isErrorModify
    ]);
    return null;
};
