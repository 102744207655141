import { useEffect, useState } from "react";
import { checkWindow, getButtonActionByActionType, setItemCookie } from "@vfit/shared/data-access";
import { PARTNER_OFFER_COOKIE, TOKEN_PARTNER_OFFER, usePartnerOfferValidate } from "@vfit/consumer/data-access";
import { errorManager, ErrorService } from "@vfit/shared/providers";
import { useRouter } from "next/router";
export function usePartnerOfferFlow(param) {
    var product = param.product;
    var ref, ref1;
    var push = useRouter().push;
    var ref2 = useState(""), tokenPartnerOffer = ref2[0], setTokenPartnerOffer = ref2[1];
    var isPartnerOffer = product === null || product === void 0 ? void 0 : (ref = product.partnerOffer) === null || ref === void 0 ? void 0 : ref.isPartnerOffer;
    var errorPartnerOffer = product === null || product === void 0 ? void 0 : (ref1 = product.partnerOffer) === null || ref1 === void 0 ? void 0 : ref1.errorPartnerOffer;
    var ref3 = usePartnerOfferValidate(tokenPartnerOffer, {
        enabled: !!tokenPartnerOffer
    }), partnerOfferData = ref3.data, isErrorPartnerOffer = ref3.isError, isSuccessPartnerOffer = ref3.isSuccess, isLoadingPartnerOffer = ref3.isLoading;
    var manageErrorPartnerOffer = function() {
        var ref;
        var redirectHomePage = function() {
            window.location.href = "/";
        };
        errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
            title: (errorPartnerOffer === null || errorPartnerOffer === void 0 ? void 0 : errorPartnerOffer.title) || "Ops",
            message: (errorPartnerOffer === null || errorPartnerOffer === void 0 ? void 0 : errorPartnerOffer.description) || "Si \xe8 verificato un problema. Riprovare in seguito",
            actionText: (errorPartnerOffer === null || errorPartnerOffer === void 0 ? void 0 : (ref = errorPartnerOffer.action) === null || ref === void 0 ? void 0 : ref.title) || "Chiudi",
            fullscreen: true,
            disableCloseModalOnClickAction: true,
            onClose: redirectHomePage,
            actionEvent: function() {
                if (errorPartnerOffer === null || errorPartnerOffer === void 0 ? void 0 : errorPartnerOffer.action) getButtonActionByActionType(errorPartnerOffer.action, push);
                else redirectHomePage();
            }
        });
    };
    var checkIsPartnerOffer = function() {
        var ref, ref1;
        if (!checkWindow()) {
            return;
        }
        if (!isPartnerOffer) return;
        var queryString = new URLSearchParams(window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.search);
        var token = (ref1 = queryString === null || queryString === void 0 ? void 0 : queryString.get(TOKEN_PARTNER_OFFER)) === null || ref1 === void 0 ? void 0 : ref1.replace(/ /g, "+");
        if (!token) {
            manageErrorPartnerOffer();
        } else {
            setTokenPartnerOffer(token);
        }
    };
    useEffect(function() {
        if (isSuccessPartnerOffer) {
            setItemCookie(PARTNER_OFFER_COOKIE, partnerOfferData, 0.021);
        }
    }, [
        isSuccessPartnerOffer
    ]);
    useEffect(function() {
        if (isErrorPartnerOffer) {
            manageErrorPartnerOffer();
        }
    }, [
        isErrorPartnerOffer
    ]);
    useEffect(function() {
        checkIsPartnerOffer();
    }, []);
    return {
        partnerOfferData: partnerOfferData,
        isErrorPartnerOffer: isErrorPartnerOffer,
        isSuccessPartnerOffer: isSuccessPartnerOffer,
        isLoadingPartnerOffer: isLoadingPartnerOffer
    };
}
