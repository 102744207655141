import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
export var organizeSimTypeCard = function(dataFromCms, errorPortability) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31, ref32;
    return {
        cardTitle: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.personalinfomobile) === null || ref === void 0 ? void 0 : (ref1 = ref.simType) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.card) === null || ref2 === void 0 ? void 0 : ref2.title) || "eSim",
        cardDescription: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref3 = dataFromCms.personalinfomobile) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.simType) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.card) === null || ref5 === void 0 ? void 0 : ref5.description) || "",
        modalInfo: {
            textLink: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref6 = dataFromCms.personalinfomobile) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.simType) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.modalInfo) === null || ref8 === void 0 ? void 0 : ref8.textLink) || "",
            titleModal: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref9 = dataFromCms.personalinfomobile) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.simType) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.modalInfo) === null || ref11 === void 0 ? void 0 : ref11.title) || "",
            descriptionModal: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref12 = dataFromCms.personalinfomobile) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.simType) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.modalInfo) === null || ref14 === void 0 ? void 0 : ref14.description) || ""
        },
        migrationCodeError: {
            title: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref15 = errorPortability.portabilitymobile) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.errorOnPortability) === null || ref16 === void 0 ? void 0 : ref16.title) || "Ops",
            description: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref17 = errorPortability.portabilitymobile) === null || ref17 === void 0 ? void 0 : (ref18 = ref17.errorOnPortability) === null || ref18 === void 0 ? void 0 : ref18.message) || "Il numero non pu\xf2 essere portato in Vodafone",
            buttonLabel: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref19 = errorPortability.portabilitymobile) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.errorOnPortability) === null || ref20 === void 0 ? void 0 : ref20.buttonLabel) || "Riprova"
        },
        genericsError: {
            title: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref21 = errorPortability.portabilitymobile) === null || ref21 === void 0 ? void 0 : (ref22 = ref21.errorModal) === null || ref22 === void 0 ? void 0 : ref22.title) || "Ops",
            description: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref23 = errorPortability.portabilitymobile) === null || ref23 === void 0 ? void 0 : (ref24 = ref23.errorModal) === null || ref24 === void 0 ? void 0 : ref24.message) || "Si \xe8 verificato un errore.",
            buttonLabel: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref25 = errorPortability.portabilitymobile) === null || ref25 === void 0 ? void 0 : (ref26 = ref25.errorModal) === null || ref26 === void 0 ? void 0 : ref26.buttonLabel) || "Riprova"
        },
        numberError: {
            title: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref27 = errorPortability.portabilitymobile) === null || ref27 === void 0 ? void 0 : (ref28 = ref27.errorNumber) === null || ref28 === void 0 ? void 0 : ref28.title) || "Ops",
            description: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref29 = errorPortability.portabilitymobile) === null || ref29 === void 0 ? void 0 : (ref30 = ref29.errorNumber) === null || ref30 === void 0 ? void 0 : ref30.message) || "Numero errato per la portabilit\xe0",
            buttonLabel: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref31 = errorPortability.portabilitymobile) === null || ref31 === void 0 ? void 0 : (ref32 = ref31.errorNumber) === null || ref32 === void 0 ? void 0 : ref32.buttonLabel) || "Riprova"
        }
    };
};
export var alreadyDonePortability = function() {
    var ref;
    var checkPortInFeasibility = getFromLocalStorageByKey("checkPortInFeasibilityIIQuery");
    return (checkPortInFeasibility === null || checkPortInFeasibility === void 0 ? void 0 : (ref = checkPortInFeasibility.resultDescription) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "success" || checkPortInFeasibility && Object.keys(checkPortInFeasibility).length === 0;
};
