import { usePatchCustomer, ErrorCodes, getCustomerIdentification, getCustomerCountry, getCustomerDocument } from "@vfit/consumer/data-access";
import { useEffect, useState } from "react";
export var useUpdateUserFlow = function(owningData, enable) {
    var ref = useState(false), isSuccess = ref[0], setIsSuccess = ref[1];
    var ref1 = useState(false), isLoading = ref1[0], setIsLoading = ref1[1];
    var ref2 = useState(false), isError = ref2[0], setIsError = ref2[1];
    var ref3 = useState(""), errorCode = ref3[0], setErrorCode = ref3[1];
    var ref4 = usePatchCustomer(), patchCustomerMutate = ref4.mutate, patchCustomer = ref4.data, isErrorPatchCustomer = ref4.isError, isSuccessPatchCustomer = ref4.isSuccess, errorPatchCustomer = ref4.error;
    var setDateFormat = function(date) {
        var day = date.substring(0, 2);
        var month = date.substring(3, 5);
        var year = date.substring(6);
        return year.concat("-", month, "-", day);
    };
    var getPayload = function(oData, index, operation) {
        var ref, ref1, ref2, ref3;
        var contactMedium = ((oData === null || oData === void 0 ? void 0 : oData.owningIndividual) || {}).contactMedium;
        var identification = getCustomerIdentification(oData === null || oData === void 0 ? void 0 : oData.owningIndividual);
        var selectedCountry = getCustomerCountry(owningData);
        var selectedDocument = getCustomerDocument(owningData);
        return [
            {
                updatedValue: selectedDocument === null || selectedDocument === void 0 ? void 0 : (ref = selectedDocument[0]) === null || ref === void 0 ? void 0 : ref.id,
                type: "identificationType",
                operation: operation,
                index: index
            },
            {
                updatedValue: identification === null || identification === void 0 ? void 0 : identification.identificationNumber,
                type: "identificationNumber",
                operation: operation,
                index: index
            },
            {
                updatedValue: setDateFormat(identification === null || identification === void 0 ? void 0 : identification.expirationDate),
                type: "expirationDate",
                operation: operation,
                index: index
            },
            {
                updatedValue: (selectedCountry === null || selectedCountry === void 0 ? void 0 : (ref1 = selectedCountry[0]) === null || ref1 === void 0 ? void 0 : ref1.id) || "",
                type: "nationality",
                operation: operation,
                index: index
            },
            {
                updatedValue: (contactMedium === null || contactMedium === void 0 ? void 0 : (ref2 = contactMedium[0]) === null || ref2 === void 0 ? void 0 : ref2.emailAddress) || "",
                type: "email",
                operation: operation,
                index: 0
            },
            {
                updatedValue: (contactMedium === null || contactMedium === void 0 ? void 0 : (ref3 = contactMedium[1]) === null || ref3 === void 0 ? void 0 : ref3.phoneNumber) || "",
                type: "number",
                operation: operation,
                index: 1
            }, 
        ];
    };
    var onCreateCustomerError = function() {
        var ref, ref1, ref2;
        if (((ref = patchCustomer) === null || ref === void 0 ? void 0 : ref.status) == "400") {
            setErrorCode(ErrorCodes.CREATE_CUSTOMER_DOCUMENT_ALREADY_PRESENT);
            return;
        }
        var serverError = (ref1 = patchCustomer) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.headers) === null || ref2 === void 0 ? void 0 : ref2.get("ERR-errorCode");
        switch(serverError){
            case ErrorCodes.CREATE_CUSTOMER_DOCUMENT_ALREADY_PRESENT:
                setErrorCode(ErrorCodes.CREATE_CUSTOMER_DOCUMENT_ALREADY_PRESENT);
                break;
            case ErrorCodes.CREATE_CUSTOMER_FISCAL_CODE_ALREADY_PRESENT:
                setErrorCode(ErrorCodes.CREATE_CUSTOMER_FISCAL_CODE_ALREADY_PRESENT);
                break;
            default:
                setErrorCode(ErrorCodes.CREATE_CUSTOMER_GENERIC_ERROR);
                break;
        }
    };
    var updateUser = function() {
        var payload = getPayload(owningData, 0, "replace");
        patchCustomerMutate(payload);
    };
    useEffect(function() {
        if (isSuccessPatchCustomer) {
            setIsLoading(false);
            setIsError(false);
            setIsSuccess(true);
        }
    }, [
        isSuccessPatchCustomer
    ]);
    useEffect(function() {
        if (isSuccessPatchCustomer && patchCustomer) {
            localStorage.setItem("customerData", JSON.stringify([
                patchCustomer
            ]));
        }
    }, [
        isSuccessPatchCustomer
    ]);
    useEffect(function() {
        if (isErrorPatchCustomer) {
            setIsSuccess(false);
            setIsLoading(false);
            setIsError(true);
            onCreateCustomerError();
        }
    }, [
        isErrorPatchCustomer
    ]);
    useEffect(function() {
        if (enable) {
            setIsLoading(true);
            updateUser();
        }
    }, [
        enable
    ]);
    return {
        isSuccess: isSuccess,
        isLoading: isLoading,
        isError: isError,
        error: errorPatchCustomer,
        errorCode: errorCode
    };
};
