import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { checkBypassApiCallFromSIA, checkIsGoBackSIA, errorMock, useGetCustomer } from "@vfit/consumer/data-access";
import { useEffect } from "react";
import { useCheckout } from "../../../iBuyFixed.context";
import { useTermsFlow } from "../UseTermsFlow/useTermsFlow";
export var useCustomerAsyncInformation = function() {
    var ref = useCheckout(), product = ref.product, termsAndConditions = ref.termsAndConditions, customerId = ref.customerId, isStartRecognition = ref.isStartRecognition, customerAsyncInfo = ref.customerAsyncInfo, setIsStartRecognition = ref.setIsStartRecognition, setTermsAndConditions = ref.setTermsAndConditions, setCustomerAsyncInfo = ref.setCustomerAsyncInfo;
    // if the product is lockin, calls do not need to be repeated
    var alreadyFetchedInfo = (product === null || product === void 0 ? void 0 : product.isLockInMMProduct) && !checkIsGoBackSIA();
    var ref1 = useTermsFlow(termsAndConditions, checkBypassApiCallFromSIA(), true, !!customerId), errorTermsAndConditions = ref1.error, isSuccessTermsAndConditions = ref1.isSuccess, isErrorTermsAndConditions = ref1.isError, isLoadingTermsAndConditions = ref1.isLoading;
    var ref2 = useGetCustomer(customerId, {
        enabled: false
    }), customerInfoData = ref2.data, isSuccessCustomerInfo = ref2.isSuccess, isLoadingCustomerInfo = ref2.isLoading, isErrorCustomerInfo = ref2.isError, customerInfoError = ref2.error, refetchCustomerInfo = ref2.refetch;
    useEffect(function() {
        if (isStartRecognition) setIsStartRecognition(false);
    }, [
        isStartRecognition
    ]);
    useEffect(function() {
        if (isLoadingCustomerInfo) {
            setCustomerAsyncInfo(_object_spread_props(_object_spread({}, customerAsyncInfo), {
                customerInfo: _object_spread_props(_object_spread({}, customerAsyncInfo.customerInfo), {
                    error: undefined,
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isErrorCustomerInfo) {
            setCustomerAsyncInfo(_object_spread_props(_object_spread({}, customerAsyncInfo), {
                customerInfo: _object_spread_props(_object_spread({}, customerAsyncInfo.customerInfo), {
                    isSuccess: false,
                    isLoading: false,
                    isError: true,
                    error: errorMock("customerInfo", customerInfoError)
                })
            }));
        } else if (isSuccessCustomerInfo) {
            setCustomerAsyncInfo(_object_spread_props(_object_spread({}, customerAsyncInfo), {
                customerInfo: _object_spread_props(_object_spread({}, customerAsyncInfo.customerInfo), {
                    error: undefined,
                    data: customerInfoData,
                    isLoading: false,
                    isError: false,
                    isSuccess: true
                })
            }));
        }
    }, [
        isErrorCustomerInfo,
        isLoadingCustomerInfo,
        isSuccessCustomerInfo
    ]);
    useEffect(function() {
        if (isLoadingTermsAndConditions) {
            setCustomerAsyncInfo(_object_spread_props(_object_spread({}, customerAsyncInfo), {
                termsAndConditions: _object_spread_props(_object_spread({}, customerAsyncInfo.termsAndConditions), {
                    error: undefined,
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isErrorTermsAndConditions) {
            setCustomerAsyncInfo(_object_spread_props(_object_spread({}, customerAsyncInfo), {
                termsAndConditions: _object_spread_props(_object_spread({}, customerAsyncInfo.termsAndConditions), {
                    error: errorTermsAndConditions,
                    isSuccess: false,
                    isError: true,
                    isLoading: false
                })
            }));
        } else if (isSuccessTermsAndConditions) {
            setTermsAndConditions(_object_spread_props(_object_spread({}, termsAndConditions), {
                isSuccessTermsAndConditions: isSuccessTermsAndConditions,
                isLoadingTermsAndConditions: isLoadingTermsAndConditions,
                isErrorTermsAndConditions: isErrorTermsAndConditions
            }));
            setCustomerAsyncInfo(_object_spread_props(_object_spread({}, customerAsyncInfo), {
                termsAndConditions: _object_spread_props(_object_spread({}, customerAsyncInfo.termsAndConditions), {
                    error: undefined,
                    isError: false,
                    isSuccess: true,
                    isLoading: false
                })
            }));
        }
    }, [
        isErrorTermsAndConditions,
        isLoadingTermsAndConditions,
        isSuccessTermsAndConditions
    ]);
    useEffect(function() {
        if (customerId && !alreadyFetchedInfo && !checkIsGoBackSIA()) {
            refetchCustomerInfo();
        }
    }, [
        customerId
    ]);
    return null;
};
