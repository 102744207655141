import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { checkIsGoBackSIA, useAuthorizeMutation, useGetShoppingCartSilentUpdate, usePaymentMethod } from "@vfit/consumer/data-access";
import { EMethodCode } from "@vfit/shared/models";
import { LoggerInstance, resetData } from "@vfit/shared/providers";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
export var useUpdatePaymentMethodFlow = function(billingOfferIdForChangePaymentMethod, orderActionId) {
    var flowEnabler = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false, selectedPaymentMethod = arguments.length > 3 ? arguments[3] : void 0, siaFlowType = arguments.length > 4 ? arguments[4] : void 0;
    var queryClient = useQueryClient();
    var ref = useState(false), isSuccess = ref[0], setIsSuccess = ref[1];
    var ref1 = useState(false), isLoading = ref1[0], setIsLoading = ref1[1];
    var ref2 = useState(false), isError = ref2[0], setIsError = ref2[1];
    var ref3 = useState(null), error = ref3[0], setError = ref3[1];
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
    var ref4 = usePaymentMethod(), paymentMethodPutIsSuccess = ref4.isSuccess, paymentMethodPutIsLoading = ref4.isLoading, paymentMethodPutIsError = ref4.isError, paymentMethodPutMutate = ref4.mutate, paymentMethodPutReset = ref4.reset;
    var ref5 = useGetShoppingCartSilentUpdate(cartId, {
        enabled: false
    }), getShoppingCartIsSuccess = ref5.isSuccess, getShoppingCartIsLoading = ref5.isLoading, getShoppingCartIsError = ref5.isError, getShoppingCartRefetch = ref5.refetch;
    var ref6 = useAuthorizeMutation(), mutateAuthorize = ref6.mutate, isSuccessAuthorize = ref6.isSuccess, isErrorAuthorize = ref6.isError, isLoadingAuthorize = ref6.isLoading;
    useEffect(function() {
        if (flowEnabler && orderActionId && !paymentMethodPutIsLoading && !paymentMethodPutIsSuccess && !paymentMethodPutIsError && !isLoadingAuthorize && !getShoppingCartIsLoading && selectedPaymentMethod) {
            paymentMethodPutMutate({
                billingOfferIdForChangePaymentMethod: billingOfferIdForChangePaymentMethod,
                orderActionId: orderActionId,
                selectedPaymentMethod: selectedPaymentMethod
            });
            setIsLoading(true);
        }
    }, [
        flowEnabler,
        selectedPaymentMethod,
        billingOfferIdForChangePaymentMethod,
        orderActionId,
        paymentMethodPutIsLoading,
        paymentMethodPutIsSuccess, 
    ]);
    var handleResetState = function() {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(false);
        setError(null);
        resetData(queryClient, [
            "getShoppingCart"
        ]);
        paymentMethodPutReset();
    };
    useEffect(function() {
        if (!paymentMethodPutIsLoading && paymentMethodPutIsSuccess && !checkIsGoBackSIA()) {
            if (selectedPaymentMethod && (selectedPaymentMethod === null || selectedPaymentMethod === void 0 ? void 0 : selectedPaymentMethod.methodCode) !== EMethodCode.WALLET && !isErrorAuthorize) {
                mutateAuthorize(_object_spread({
                    payMeanType: selectedPaymentMethod.methodCode
                }, siaFlowType && {
                    siaFlowType: siaFlowType
                }));
            } else {
                getShoppingCartRefetch();
            }
        }
        if (!paymentMethodPutIsLoading && paymentMethodPutIsError) {
            var errorPut = queryClient.getQueryData("paymentMethod");
            LoggerInstance.debug("paymentMethodPut error!!");
            setIsLoading(false);
            setIsError(true);
            setError(errorPut || null);
        }
        if (isErrorAuthorize) {
            var errorAuthorize = queryClient.getQueryData("authorize");
            LoggerInstance.debug("authorize error!!");
            setIsLoading(false);
            setIsError(true);
            setError(errorAuthorize || null);
        }
    }, [
        paymentMethodPutIsLoading,
        paymentMethodPutIsSuccess,
        paymentMethodPutIsError,
        isErrorAuthorize, 
    ]);
    useEffect(function() {
        if (isErrorAuthorize && !isLoadingAuthorize) {
            setIsError(true);
            setIsLoading(false);
            setIsSuccess(false);
        } else if (!isLoadingAuthorize && isSuccessAuthorize) {
            getShoppingCartRefetch();
        }
    }, [
        isSuccessAuthorize,
        isLoadingAuthorize,
        isErrorAuthorize
    ]);
    useEffect(function() {
        if (getShoppingCartIsError && !getShoppingCartIsLoading) {
            setIsError(true);
            setIsLoading(false);
            setIsSuccess(false);
        } else if (getShoppingCartIsSuccess && !getShoppingCartIsLoading) {
            setIsError(false);
            setIsLoading(false);
            setIsSuccess(true);
        }
    }, [
        getShoppingCartIsSuccess,
        getShoppingCartIsError,
        getShoppingCartIsLoading
    ]);
    useEffect(function() {
        if (!isLoading) handleResetState();
    }, [
        isLoading
    ]);
    return {
        isSuccess: isSuccess,
        isLoading: isLoading,
        isError: isError,
        error: error,
        handleResetState: handleResetState
    };
};
