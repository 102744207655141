import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect } from "react";
import { CMS_CONFIG, COOKIE_CART, errorMock, useCmsConfig, useDeleteShoppingCartMutation } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey, PAGES, setItemCookie, trackView } from "@vfit/shared/data-access";
import { errorManager, ErrorService, LoggerInstance, toastManager } from "@vfit/shared/providers";
import { organizeSimTypeCard } from "../../components/SimTypeSelectionCard/simTypeSelectionCard.utils";
import { getTrackError } from "../../checkout.utils";
import { useCheckout } from "../../../iBuyMobile.context";
import { ENABLE_DEBUG } from "../../../iBuyMobile.utils";
export var useCheckoutErrors = function(param) {
    var handleClose = param.handleClose, replaceBillingAddress = param.replaceBillingAddress, createBillingAddress = param.createBillingAddress;
    var ref = useCheckout(), product = ref.product, currentStepKey = ref.currentStepKey, customerDeliveryBaseInfo = ref.customerDeliveryBaseInfo, familyPlus = ref.familyPlus, cartAsyncInfo = ref.cartAsyncInfo, customerAsyncInfo = ref.customerAsyncInfo, customerFlow = ref.customerFlow, deliveryAdvance = ref.deliveryAdvance, authorizationAsyncInfo = ref.authorizationAsyncInfo, checkoutErrors = ref.checkoutErrors, setCheckoutErrors = ref.setCheckoutErrors;
    var ref1 = useDeleteShoppingCartMutation(), deleteCartMutation = ref1.mutate, isSuccessDeleteCart = ref1.isSuccess, isLoadingDeleteCart = ref1.isLoading, isErrorDeleteCart = ref1.isError;
    var personalDataMobileCMS = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_PERSONAL_DATA_MOBILE);
    var errorPortability = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_PORTABILITY_MOBILE);
    var genericError = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GENERIC_ERROR);
    var organizedDataForSim = organizeSimTypeCard(personalDataMobileCMS, errorPortability);
    var showAndTrackError = function(_genericError, _handleClose, error, title, message, actionEvent, actionText, secondActionEvent, secondActionText) {
        var isButtonSticky = arguments.length > 9 && arguments[9] !== void 0 ? arguments[9] : false, disableDeleteCart = arguments.length > 10 && arguments[10] !== void 0 ? arguments[10] : false, disableTrack = arguments.length > 11 && arguments[11] !== void 0 ? arguments[11] : false;
        var ref, ref1, ref2, ref3, ref4, ref5;
        var trackErrorRes = error ? getTrackError(error, currentStepKey === null || currentStepKey === void 0 ? void 0 : currentStepKey.toLowerCase()) : undefined;
        var deleteCartClose = function() {
            _handleClose === null || _handleClose === void 0 ? void 0 : _handleClose();
            window.location.reload();
        };
        var STATUS_DELETE_CART = [
            "500",
            "403",
            "404"
        ];
        var actualCart = getFromLocalStorageByKey("shoppingCart");
        var cartId = actualCart === null || actualCart === void 0 ? void 0 : actualCart.id;
        var ENABLE_DELETE_CART = !disableDeleteCart && cartId && (error === null || error === void 0 ? void 0 : (ref = error.error) === null || ref === void 0 ? void 0 : ref.status) && STATUS_DELETE_CART.includes(error === null || error === void 0 ? void 0 : (ref1 = error.error) === null || ref1 === void 0 ? void 0 : ref1.status) || (error === null || error === void 0 ? void 0 : (ref2 = error.error) === null || ref2 === void 0 ? void 0 : ref2.status) === "timeout";
        if (ENABLE_DELETE_CART && !isSuccessDeleteCart && !isLoadingDeleteCart && !isErrorDeleteCart) {
            deleteCartMutation({
                shoppingCartId: cartId,
                customOptions: {
                    headers: {
                        Authorization: sessionStorage.getItem("uxfauthorization") || ""
                    }
                }
            });
        }
        if (trackErrorRes) {
            if (ENABLE_DEBUG) {
                toastManager.handleToast({
                    title: trackErrorRes.page_error || "no error found"
                });
            }
            LoggerInstance.log("Track", trackErrorRes);
            trackView(trackErrorRes);
        }
        errorManager.handleError(ErrorService.getSeverityErrorHigh(), _object_spread_props(_object_spread({
            title: title || (_genericError === null || _genericError === void 0 ? void 0 : (ref3 = _genericError.genericerror) === null || ref3 === void 0 ? void 0 : ref3.title) || "",
            message: message || (_genericError === null || _genericError === void 0 ? void 0 : (ref4 = _genericError.genericerror) === null || ref4 === void 0 ? void 0 : ref4.description) || "",
            onClose: ENABLE_DELETE_CART ? deleteCartClose : _handleClose,
            actionEvent: ENABLE_DELETE_CART ? deleteCartClose : actionEvent || _handleClose,
            actionText: actionText || (_genericError === null || _genericError === void 0 ? void 0 : (ref5 = _genericError.genericerror) === null || ref5 === void 0 ? void 0 : ref5.buttonLabel) || "",
            disableTrack: !!trackErrorRes || disableTrack,
            secondActionEvent: secondActionEvent,
            secondButtonText: secondActionText,
            isButtonSticky: isButtonSticky
        }, trackErrorRes && {
            trackError: _object_spread({}, trackErrorRes)
        }), {
            opts: {
                product_name: (product === null || product === void 0 ? void 0 : product.slug) || ""
            }
        }));
    };
    // region ERRORS
    useEffect(function() {
        if (customerDeliveryBaseInfo === null || customerDeliveryBaseInfo === void 0 ? void 0 : customerDeliveryBaseInfo.modifySimDevice.isError) {
            var ref, ref1, ref2, ref3;
            LoggerInstance.log("Error:modifysimdevice_error");
            checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref3 = checkoutErrors.showAndTrackError) === null || ref3 === void 0 ? void 0 : ref3.call(checkoutErrors, genericError, handleClose, _object_spread_props(_object_spread({}, customerDeliveryBaseInfo === null || customerDeliveryBaseInfo === void 0 ? void 0 : (ref = customerDeliveryBaseInfo.modifySimDevice) === null || ref === void 0 ? void 0 : ref.error), {
                isBlocking: true
            }), organizedDataForSim === null || organizedDataForSim === void 0 ? void 0 : (ref1 = organizedDataForSim.genericsError) === null || ref1 === void 0 ? void 0 : ref1.title, organizedDataForSim === null || organizedDataForSim === void 0 ? void 0 : (ref2 = organizedDataForSim.genericsError) === null || ref2 === void 0 ? void 0 : ref2.description, handleClose, organizedDataForSim.genericsError.buttonLabel);
        }
    }, [
        customerDeliveryBaseInfo === null || customerDeliveryBaseInfo === void 0 ? void 0 : customerDeliveryBaseInfo.modifySimDevice
    ]);
    useEffect(function() {
        if (customerDeliveryBaseInfo === null || customerDeliveryBaseInfo === void 0 ? void 0 : customerDeliveryBaseInfo.deliveryBase.isError) {
            var ref, ref1;
            LoggerInstance.log("Error:deliverybase_error");
            checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref1 = checkoutErrors.showAndTrackError) === null || ref1 === void 0 ? void 0 : ref1.call(checkoutErrors, genericError, handleClose, _object_spread_props(_object_spread({}, customerDeliveryBaseInfo === null || customerDeliveryBaseInfo === void 0 ? void 0 : (ref = customerDeliveryBaseInfo.deliveryBase) === null || ref === void 0 ? void 0 : ref.error), {
                isBlocking: true
            }));
        }
    }, [
        customerDeliveryBaseInfo === null || customerDeliveryBaseInfo === void 0 ? void 0 : customerDeliveryBaseInfo.deliveryBase
    ]);
    useEffect(function() {
        if (customerFlow === null || customerFlow === void 0 ? void 0 : customerFlow.billingAccount.isError) {
            var ref, ref1;
            LoggerInstance.log("Error: billingaccount_error");
            checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref1 = checkoutErrors.showAndTrackError) === null || ref1 === void 0 ? void 0 : ref1.call(checkoutErrors, genericError, handleClose, _object_spread_props(_object_spread({}, customerFlow === null || customerFlow === void 0 ? void 0 : (ref = customerFlow.billingAccount) === null || ref === void 0 ? void 0 : ref.error), {
                isBlocking: true
            }));
        }
    }, [
        customerFlow === null || customerFlow === void 0 ? void 0 : customerFlow.billingAccount
    ]);
    useEffect(function() {
        if (customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : customerAsyncInfo.termsAndConditions.isError) {
            var ref, ref1;
            LoggerInstance.log("Error: termsandcondition_error");
            checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref1 = checkoutErrors.showAndTrackError) === null || ref1 === void 0 ? void 0 : ref1.call(checkoutErrors, genericError, handleClose, _object_spread_props(_object_spread({}, customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref = customerAsyncInfo.termsAndConditions) === null || ref === void 0 ? void 0 : ref.error), {
                isBlocking: true
            }));
        }
    }, [
        customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : customerAsyncInfo.termsAndConditions
    ]);
    useEffect(function() {
        if (customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : customerAsyncInfo.retrieveMultilines.isError) {
            var ref, ref1;
            LoggerInstance.log("Error: retrieveMultilines_error");
            checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref1 = checkoutErrors.showAndTrackError) === null || ref1 === void 0 ? void 0 : ref1.call(checkoutErrors, genericError, handleClose, _object_spread_props(_object_spread({}, customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref = customerAsyncInfo.retrieveMultilines) === null || ref === void 0 ? void 0 : ref.error), {
                isBlocking: true
            }));
        }
    }, [
        customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : customerAsyncInfo.retrieveMultilines
    ]);
    useEffect(function() {
        var ref;
        if (authorizationAsyncInfo === null || authorizationAsyncInfo === void 0 ? void 0 : (ref = authorizationAsyncInfo.payMean) === null || ref === void 0 ? void 0 : ref.isError) {
            var ref1, ref2;
            LoggerInstance.log("Error: paymean_error");
            checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref2 = checkoutErrors.showAndTrackError) === null || ref2 === void 0 ? void 0 : ref2.call(checkoutErrors, _object_spread_props(_object_spread({}, genericError), {
                genericerror: _object_spread_props(_object_spread({}, genericError.genericerror), {
                    title: "Ops!",
                    description: "Si \xe8 verificato un errore durante il recupero dei pagamenti. Riprovare in seguito"
                })
            }), handleClose, _object_spread_props(_object_spread({}, authorizationAsyncInfo === null || authorizationAsyncInfo === void 0 ? void 0 : (ref1 = authorizationAsyncInfo.payMean) === null || ref1 === void 0 ? void 0 : ref1.error), {
                isBlocking: true
            }));
        }
    }, [
        authorizationAsyncInfo === null || authorizationAsyncInfo === void 0 ? void 0 : authorizationAsyncInfo.payMean
    ]);
    useEffect(function() {
        if (deliveryAdvance === null || deliveryAdvance === void 0 ? void 0 : deliveryAdvance.isError) {
            var ref;
            LoggerInstance.log("Error: deliveryadvance_error");
            checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref = checkoutErrors.showAndTrackError) === null || ref === void 0 ? void 0 : ref.call(checkoutErrors, genericError, handleClose, _object_spread_props(_object_spread({}, deliveryAdvance === null || deliveryAdvance === void 0 ? void 0 : deliveryAdvance.error), {
                isBlocking: true
            }));
        }
    }, [
        deliveryAdvance
    ]);
    useEffect(function() {
        var ref;
        if (cartAsyncInfo === null || cartAsyncInfo === void 0 ? void 0 : (ref = cartAsyncInfo.simTypeSelection) === null || ref === void 0 ? void 0 : ref.isError) {
            var ref1, ref2;
            LoggerInstance.log("Error: simtypeselection_error");
            checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref2 = checkoutErrors.showAndTrackError) === null || ref2 === void 0 ? void 0 : ref2.call(checkoutErrors, genericError, handleClose, _object_spread_props(_object_spread({}, cartAsyncInfo === null || cartAsyncInfo === void 0 ? void 0 : (ref1 = cartAsyncInfo.simTypeSelection) === null || ref1 === void 0 ? void 0 : ref1.error), {
                isBlocking: true
            }));
        }
    }, [
        cartAsyncInfo
    ]);
    useEffect(function() {
        var ref;
        if (familyPlus === null || familyPlus === void 0 ? void 0 : (ref = familyPlus.updateCart) === null || ref === void 0 ? void 0 : ref.isError) {
            var ref1, ref2;
            LoggerInstance.log("Error: familyPlus_error");
            checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref2 = checkoutErrors.showAndTrackError) === null || ref2 === void 0 ? void 0 : ref2.call(checkoutErrors, genericError, handleClose, _object_spread_props(_object_spread({}, familyPlus === null || familyPlus === void 0 ? void 0 : (ref1 = familyPlus.updateCart) === null || ref1 === void 0 ? void 0 : ref1.error), {
                isBlocking: true
            }));
        }
    }, [
        familyPlus
    ]);
    useEffect(function() {
        if (replaceBillingAddress === null || replaceBillingAddress === void 0 ? void 0 : replaceBillingAddress.isError) {
            var ref;
            LoggerInstance.log("Error: replacebillingaddress_error");
            checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref = checkoutErrors.showAndTrackError) === null || ref === void 0 ? void 0 : ref.call(checkoutErrors, genericError, handleClose, _object_spread_props(_object_spread({}, errorMock("replacebilling", {
                url: "replacebilling",
                status: "500",
                statusText: "500"
            })), {
                isBlocking: true
            }));
        }
    }, [
        replaceBillingAddress
    ]);
    useEffect(function() {
        if (createBillingAddress === null || createBillingAddress === void 0 ? void 0 : createBillingAddress.isError) {
            var ref;
            LoggerInstance.log("Error: createbilling_error");
            checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref = checkoutErrors.showAndTrackError) === null || ref === void 0 ? void 0 : ref.call(checkoutErrors, genericError, handleClose, _object_spread_props(_object_spread({}, errorMock("createbilling", {
                url: "createbilling",
                status: "500",
                statusText: "500"
            })), {
                isBlocking: true
            }));
        }
    }, [
        createBillingAddress
    ]);
    // endregion
    // if is success delete cart from popup, remove cookie for delete cart on reload
    useEffect(function() {
        if (isSuccessDeleteCart) setItemCookie(COOKIE_CART, "", 0);
    }, [
        isSuccessDeleteCart
    ]);
    useEffect(function() {
        setCheckoutErrors(_object_spread_props(_object_spread({}, checkoutErrors), {
            showAndTrackError: showAndTrackError
        }));
    }, [
        currentStepKey
    ]);
    return null;
};
