import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import DocumentIdCard from "./components/DocumentIdCard/documentIdCard";
import NameFiscalCodeCard from "./components/NameFiscalCodeCard/nameFiscalCodeCard";
import ContactCard from "./components/ContactCard/contactCard";
import OtpCard from "./components/OtpCard/otpCard";
import ConfirmIdentityCard from "./components/ConfirmIdentityCard/confirmIdentityCard";
import PortabilityCard from "./components/PortabilityCard/portabilityCard";
import PortabilityNumberCard from "./components/PortabilityNumberCard/portabilityNumberCard";
import YourAddressCard from "./components/YourAddressCard/yourAddressCard";
import PortabilityOtpCard from "./components/PortabilityOtpCrad/portabilityOtpCard";
import PortabilityDataCard from "./components/PortabilityDataCard/portabilityDataCard";
import RecognitionCard from "./components/RecognitionCard/recognitionCard";
import SimTypeSelectionCard from "./components/SimTypeSelectionCard/simTypeSelectionCard";
import TermsCard from "./components/TermsCard/termsCard";
import ConsentCard from "./components/ConsentCard/consentCard";
import RecurrentPaymentCard from "./components/RecurrentPaymentCard/recurrentPaymentCard";
import PaymentMethodCard from "./components/PaymentMethodCard/paymentMethodCard";
import WarningCard from "./components/WarningCard/warningCard";
import UpfrontPaymentCard from "./components/UpfrontPaymentCard/upfrontPaymentCard";
import WinbackNumberCard from "./components/WinbackNumberCard/winbackNumberCard";
import WinbackOtp from "./components/WinbackOtp/winbackOtp";
import NameFiscalCodeCardWinback from "./components/NameFiscalCodeCardWinback/nameFiscalCodeCardWinback";
import SimTypeSelectionCardWinback from "./components/SimTypeSelectionCardWinback/simTypeSelectionCardWinback";
import PortabilityNumberPortabilityRequiredCard from "./components/PortabilityNumberPortabilityRequiredCard/portabilityNumberPortabilityRequiredCard";
import PortabilityNumberCardWinback from "./components/PortabilityNumberCardWinback/portabilityNumberCardWinback";
import Voucher from "./components/VoucherCard/voucherCard";
import { ID_FLOWS } from "./checkout.constants";
export var COMPONENTS_FLOW = [
    {
        title: ID_FLOWS.PERSONAL_INFO_WINBACK,
        component: NameFiscalCodeCardWinback
    },
    {
        title: ID_FLOWS.PERSONAL_INFO,
        component: NameFiscalCodeCard
    },
    {
        title: ID_FLOWS.DOCUMENT_ID,
        component: DocumentIdCard
    },
    {
        title: ID_FLOWS.CONTACT_CARD,
        component: ContactCard
    },
    {
        title: ID_FLOWS.CONFIRM_IDENDITY,
        component: ConfirmIdentityCard
    },
    {
        title: ID_FLOWS.OTP,
        component: OtpCard
    },
    {
        title: ID_FLOWS.PORTABILITY,
        component: PortabilityCard
    },
    {
        title: ID_FLOWS.PORTABILITY_NUMBER,
        component: PortabilityNumberCard
    },
    {
        title: ID_FLOWS.PORTABILITY_NUMBER_WINBACK,
        component: PortabilityNumberCardWinback
    },
    {
        title: ID_FLOWS.PORTABILITY_NUMBER_PORTABILITY_REQUIRED,
        component: PortabilityNumberPortabilityRequiredCard
    },
    {
        title: ID_FLOWS.PORTABILITY_OTP,
        component: PortabilityOtpCard
    },
    {
        title: ID_FLOWS.PORTABILITY_DATA,
        component: PortabilityDataCard
    },
    {
        title: ID_FLOWS.TERMS_AND_CONDITIONS,
        component: TermsCard
    },
    {
        title: ID_FLOWS.CONSENTS,
        component: ConsentCard
    },
    {
        title: ID_FLOWS.RECOGNITION_ESIM,
        component: SimTypeSelectionCard
    },
    {
        title: ID_FLOWS.RECOGNITION_ESIM_WINBACK,
        component: SimTypeSelectionCardWinback
    },
    {
        title: ID_FLOWS.RECOGNITION_CARD,
        component: RecognitionCard
    },
    {
        title: ID_FLOWS.DELIVERY_ADDRESS,
        component: YourAddressCard
    },
    {
        title: ID_FLOWS.PAYMENT_METHOD,
        component: PaymentMethodCard
    },
    {
        title: ID_FLOWS.RECURRENT_PAYMENT_CARD,
        component: RecurrentPaymentCard
    },
    {
        title: ID_FLOWS.WARNING_PRICE_CHANGE,
        component: WarningCard
    },
    {
        title: ID_FLOWS.UPFRONT_PAYMENT,
        component: UpfrontPaymentCard
    },
    {
        title: ID_FLOWS.WINBACK_NUMBER,
        component: WinbackNumberCard
    },
    {
        title: ID_FLOWS.WINBACK_OTP,
        component: WinbackOtp
    },
    {
        title: ID_FLOWS.VOUCHER,
        component: Voucher
    }, 
];
export var getFlowComponentByTitle = function(title) {
    return COMPONENTS_FLOW.find(function(el) {
        return el.title === title;
    }) || {};
};
export var VOUCHER_FLOW = {
    VOUCHER: [
        getFlowComponentByTitle(ID_FLOWS.VOUCHER)
    ]
};
export var WINBACK_FLOW = {
    WINBACK_NUMBER: [
        getFlowComponentByTitle(ID_FLOWS.WINBACK_NUMBER)
    ],
    WINBACK_OTP: [
        getFlowComponentByTitle(ID_FLOWS.WINBACK_OTP)
    ]
};
var PERSONAL_INFO_FLOW = {
    PERSONAL_INFO: [
        getFlowComponentByTitle(ID_FLOWS.PERSONAL_INFO),
        getFlowComponentByTitle(ID_FLOWS.CONTACT_CARD),
        getFlowComponentByTitle(ID_FLOWS.DOCUMENT_ID), 
    ]
};
var PERSONAL_INFO_FLOW_WINBACK = {
    PERSONAL_INFO: [
        getFlowComponentByTitle(ID_FLOWS.PERSONAL_INFO_WINBACK),
        getFlowComponentByTitle(ID_FLOWS.CONTACT_CARD),
        getFlowComponentByTitle(ID_FLOWS.DOCUMENT_ID), 
    ]
};
var OTP_FLOW = {
    OTP: [
        getFlowComponentByTitle(ID_FLOWS.CONFIRM_IDENDITY),
        getFlowComponentByTitle(ID_FLOWS.OTP)
    ]
};
var PORTABILITY_FLOW = {
    PORTABILITY: [
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY)
    ],
    PORTABILITY_NUMBER: [
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY_NUMBER),
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY_DATA), 
    ],
    PORTABILITY_OTP: [
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY_OTP)
    ]
};
var PORTABILITY_FLOW_REQUIRED = {
    PORTABILITY_NUMBER: [
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY_NUMBER_PORTABILITY_REQUIRED),
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY_DATA), 
    ],
    PORTABILITY_OTP: [
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY_OTP)
    ]
};
var PORTABILITY_FLOW_WINBACK = {
    PORTABILITY_NUMBER: [
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY_NUMBER_WINBACK),
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY_DATA), 
    ],
    PORTABILITY_OTP: [
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY_OTP)
    ]
};
export var TERMS_FLOW = {
    TERMS: [
        getFlowComponentByTitle(ID_FLOWS.TERMS_AND_CONDITIONS),
        getFlowComponentByTitle(ID_FLOWS.CONSENTS), 
    ]
};
export var ESIM_SIM_RECOGNITION = {
    RECOGNITION: [
        getFlowComponentByTitle(ID_FLOWS.RECOGNITION_ESIM),
        getFlowComponentByTitle(ID_FLOWS.RECOGNITION_CARD), 
    ]
};
export var ESIM_SIM_RECOGNITION_WINBACK = {
    RECOGNITION: [
        getFlowComponentByTitle(ID_FLOWS.RECOGNITION_ESIM_WINBACK),
        getFlowComponentByTitle(ID_FLOWS.RECOGNITION_CARD), 
    ]
};
export var DELIVERY_FLOW = {
    DELIVERY: [
        getFlowComponentByTitle(ID_FLOWS.DELIVERY_ADDRESS)
    ]
};
export var PAYMENT_METHOD_FLOW = {
    PAYMENT_METHOD: [
        getFlowComponentByTitle(ID_FLOWS.PAYMENT_METHOD)
    ]
};
var PAYMENT_FLOW = {
    RECURRENT_PAYMENT_CARD: [
        getFlowComponentByTitle(ID_FLOWS.RECURRENT_PAYMENT_CARD)
    ],
    WARNING_PRICE_CHANGE: [
        getFlowComponentByTitle(ID_FLOWS.WARNING_PRICE_CHANGE)
    ]
};
export var UPFRONT_PAYMENT_FLOW = {
    UPFRONT_PAYMENT: [
        getFlowComponentByTitle(ID_FLOWS.UPFRONT_PAYMENT)
    ]
};
export var EMPTY_FLOWS = {
    EMPTY: [
        {
            title: "",
            component: undefined
        },
        {
            title: "",
            component: undefined
        }, 
    ]
};
var MOBILE_LINE_FLOW = {
    DEFAULT: _to_consumable_array(PERSONAL_INFO_FLOW.PERSONAL_INFO).concat(_to_consumable_array(PORTABILITY_FLOW.PORTABILITY), _to_consumable_array(ESIM_SIM_RECOGNITION.RECOGNITION), _to_consumable_array(DELIVERY_FLOW.DELIVERY), _to_consumable_array(TERMS_FLOW.TERMS), _to_consumable_array(VOUCHER_FLOW.VOUCHER), _to_consumable_array(PAYMENT_FLOW.RECURRENT_PAYMENT_CARD), _to_consumable_array(UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT), _to_consumable_array(EMPTY_FLOWS.EMPTY)),
    WINBACK: _to_consumable_array(WINBACK_FLOW.WINBACK_NUMBER).concat(_to_consumable_array(WINBACK_FLOW.WINBACK_OTP), _to_consumable_array(PERSONAL_INFO_FLOW_WINBACK.PERSONAL_INFO), _to_consumable_array(PORTABILITY_FLOW_WINBACK.PORTABILITY_NUMBER), _to_consumable_array(ESIM_SIM_RECOGNITION_WINBACK.RECOGNITION), _to_consumable_array(DELIVERY_FLOW.DELIVERY), _to_consumable_array(TERMS_FLOW.TERMS), _to_consumable_array(VOUCHER_FLOW.VOUCHER), _to_consumable_array(PAYMENT_FLOW.RECURRENT_PAYMENT_CARD), _to_consumable_array(UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT), _to_consumable_array(EMPTY_FLOWS.EMPTY)),
    PORTABILITY_REQUIRED: _to_consumable_array(PERSONAL_INFO_FLOW.PERSONAL_INFO).concat(_to_consumable_array(PORTABILITY_FLOW_REQUIRED.PORTABILITY_NUMBER), _to_consumable_array(ESIM_SIM_RECOGNITION.RECOGNITION), _to_consumable_array(DELIVERY_FLOW.DELIVERY), _to_consumable_array(TERMS_FLOW.TERMS), _to_consumable_array(VOUCHER_FLOW.VOUCHER), _to_consumable_array(PAYMENT_FLOW.RECURRENT_PAYMENT_CARD), _to_consumable_array(UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT), _to_consumable_array(EMPTY_FLOWS.EMPTY))
};
export { MOBILE_LINE_FLOW, PERSONAL_INFO_FLOW, OTP_FLOW, PORTABILITY_FLOW, PAYMENT_FLOW };
