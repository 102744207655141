import { LOGGED_USER_KEYS } from "@vfit/consumer/data-access";
import { useQueryClient } from "react-query";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { useCheckout } from "../iBuyMobile.context";
import { useShoppingCartInfo } from "./hooks/useShoppingCartInfo";
var useShoppingCart = function() {
    var product = useCheckout().product;
    var queryClient = useQueryClient();
    var silentLogin = queryClient.getQueryData("silentLogin");
    var IS_DISABLED_CREATE_CART = product === null || product === void 0 ? void 0 : product.isFamilyPlusProduct;
    var customerDataList = getFromLocalStorageByKey("customerData");
    var customerData = customerDataList === null || customerDataList === void 0 ? void 0 : customerDataList[0];
    var isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin === null || silentLogin === void 0 ? void 0 : silentLogin.flowId) || false;
    var ref = useShoppingCartInfo(product, customerData === null || customerData === void 0 ? void 0 : customerData.id, isLoggedUser, IS_DISABLED_CREATE_CART), isCompletedSilentLogin = ref.isCompletedSilentLogin, isLoadingShoppingCartInfo = ref.isLoading, isErrorShoppingCart = ref.isError, errorMessageShoppingCart = ref.errorMessage;
    return {
        isLoadingShoppingCartInfo: isLoadingShoppingCartInfo || !isCompletedSilentLogin,
        isErrorShoppingCart: isErrorShoppingCart,
        errorMessageShoppingCart: errorMessageShoppingCart
    };
};
export default useShoppingCart;
