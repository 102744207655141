import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useForm } from "react-hook-form";
import { API, PAGES, trackLink } from "@vfit/shared/data-access";
import { useEffect } from "react";
import { CMS_CONFIG, organizeVoucherInsertionCard, useCmsConfig } from "@vfit/consumer/data-access";
import { voucherCodeFieldName } from "./voucherInsertion.shema";
import { useCheckout } from "../../../../../iBuyMobile.context";
import { useVoucherInsertionCardTagging } from "./voucherInsertionCard.tagging";
export var useVoucherInsertionCard = function() {
    var ref, ref1, ref2;
    var ref3 = useCheckout(), setVoucher = ref3.setVoucher, setConfiguredStickyStepperLabel = ref3.setConfiguredStickyStepperLabel, voucher = ref3.voucher;
    var dataFromCms = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_MOBILE_VOUCHERS);
    var voucherCms = organizeVoucherInsertionCard(dataFromCms);
    useVoucherInsertionCardTagging();
    var ref4 = useForm({
        mode: "all",
        defaultValues: _define_property({}, voucherCodeFieldName, localStorage.getItem("voucherId") || "")
    }), register = ref4.register, _formState = ref4.formState, isValid = _formState.isValid, errors = _formState.errors, watch = ref4.watch, setError = ref4.setError;
    // This process needs to be separated from the general form validation
    // because we want to disable the btn when there's no pattern match,
    // while avoiding triggering errors if a voucher is not in the field.
    var voucherField = watch(voucherCodeFieldName);
    var handleClick = function() {
        setVoucher(_object_spread_props(_object_spread({}, voucher), {
            voucherId: voucherField
        }));
    };
    var trackSkipButton = function() {
        var ref, ref1;
        trackLink(voucherCms === null || voucherCms === void 0 ? void 0 : (ref = voucherCms.vouchers) === null || ref === void 0 ? void 0 : (ref1 = ref.insertion) === null || ref1 === void 0 ? void 0 : ref1.skipLabel);
    };
    useEffect(function() {
        var ref, ref1, ref2;
        (ref = voucher.resetData) === null || ref === void 0 ? void 0 : ref.call(voucher);
        setConfiguredStickyStepperLabel(voucherCms === null || voucherCms === void 0 ? void 0 : (ref1 = voucherCms.vouchers) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.insertion) === null || ref2 === void 0 ? void 0 : ref2.skipLabel);
        var skipButton = document.getElementById("sticky-offer-next");
        localStorage.removeItem("voucherId");
        skipButton === null || skipButton === void 0 ? void 0 : skipButton.addEventListener("click", trackSkipButton);
        return function() {
            setConfiguredStickyStepperLabel("");
            skipButton === null || skipButton === void 0 ? void 0 : skipButton.removeEventListener("click", trackSkipButton);
        };
    }, []);
    useEffect(function() {
        var ref;
        if (voucher === null || voucher === void 0 ? void 0 : (ref = voucher.validateVoucher) === null || ref === void 0 ? void 0 : ref.isSuccess) {
            if (voucher === null || voucher === void 0 ? void 0 : voucher.isExpiredVoucher) {
                setError(voucherCodeFieldName, {
                    type: "manual",
                    message: voucherCms.vouchers.insertion.expiredMessage
                });
            } else if (voucher === null || voucher === void 0 ? void 0 : voucher.isInvalidVoucher) {
                setError(voucherCodeFieldName, {
                    type: "manual",
                    message: voucherCms.vouchers.insertion.invalidMessage
                });
            }
        }
    }, [
        voucher === null || voucher === void 0 ? void 0 : (ref = voucher.validateVoucher) === null || ref === void 0 ? void 0 : ref.isSuccess
    ]);
    useEffect(function() {
        var ref;
        if (voucher === null || voucher === void 0 ? void 0 : (ref = voucher.validateVoucher) === null || ref === void 0 ? void 0 : ref.isError) {
            setError(voucherCodeFieldName, {
                type: "manual",
                message: voucherCms.vouchers.insertion.invalidMessage
            });
        }
    }, [
        voucher === null || voucher === void 0 ? void 0 : (ref1 = voucher.validateVoucher) === null || ref1 === void 0 ? void 0 : ref1.isError
    ]);
    return {
        voucherCms: voucherCms,
        errors: errors,
        handleClick: handleClick,
        isLoading: voucher === null || voucher === void 0 ? void 0 : (ref2 = voucher.validateVoucher) === null || ref2 === void 0 ? void 0 : ref2.isLoading,
        isValid: isValid,
        register: register,
        watch: watch
    };
};
