import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect } from "react";
import { errorMock, getCurrentUserType, getHubPath, IFamilyPlusErrorType, IUserType, useCampaignAccept, useCampaignsInfoMobile, useGetShoppingCart } from "@vfit/consumer/data-access";
import { usePageProvider } from "@vfit/consumer/providers";
import { updateProductPrice } from "../../../ShoppingCart/shoppingCart.utils";
import { useCheckout } from "../../../iBuyMobile.context";
export var useFamilyPlus = function() {
    var ref, ref1, ref2;
    var ref3 = useCheckout(), product = ref3.product, familyPlus = ref3.familyPlus, isStartFamilyPlus = ref3.isStartFamilyPlus, isStartFamilyPlusAccept = ref3.isStartFamilyPlusAccept, setIsLoadingCart = ref3.setIsLoadingCart, setIsStartFamilyPlus = ref3.setIsStartFamilyPlus, setIsStartAcceptFamilyPlus = ref3.setIsStartAcceptFamilyPlus, setFamilyPlus = ref3.setFamilyPlus, setProduct = ref3.setProduct;
    var page = usePageProvider().page;
    // if product is familyplus from hub the familyplus id is the offerId else the familyPlusId in poduct configuration
    var familyPlusId = (product === null || product === void 0 ? void 0 : product.isFamilyPlusProduct) ? product === null || product === void 0 ? void 0 : product.offerId : product === null || product === void 0 ? void 0 : product.familyPlusId;
    var allProducts = (page === null || page === void 0 ? void 0 : (ref = page.params) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.productsAll) || [];
    var familyPlusProduct = allProducts === null || allProducts === void 0 ? void 0 : allProducts.find(function(el) {
        return el.offerId.toString() == familyPlusId;
    });
    var currentUserType = getCurrentUserType();
    var isProspectOrInactive = currentUserType === IUserType.PROSPECT_USER || currentUserType === IUserType.NEXT_USER_INACTIVE;
    var ref4 = useCampaignsInfoMobile({
        enabled: false
    }), campaignsData = ref4.data, isSuccessCampaigns = ref4.isSuccess, isErrorCampaigns = ref4.isError, isLoadingCampaigns = ref4.isLoading, refetchCampaignsInfoMobile = ref4.refetch;
    var ref5 = useCampaignAccept(), mutateAcceptCampaign = ref5.mutate, acceptCampaignData = ref5.data, isSuccessAcceptCampaign = ref5.isSuccess, isLoadingAcceptCampaign = ref5.isLoading, isErrorAcceptCampaign = ref5.isError;
    var ref6 = useGetShoppingCart((familyPlus === null || familyPlus === void 0 ? void 0 : (ref2 = familyPlus.accept) === null || ref2 === void 0 ? void 0 : ref2.acceptId) || "", {
        enabled: false
    }), isSuccessGetShoppingCart = ref6.isSuccess, isLoadingGetShoppingCart = ref6.isLoading, isErrorGetShoppingCart = ref6.isError, errorGetShoppingCart = ref6.error, getShoppingCartRefetch = ref6.refetch;
    // region UPDATE CART
    useEffect(function() {
        if (isLoadingGetShoppingCart && isSuccessAcceptCampaign) {
            if (product === null || product === void 0 ? void 0 : product.isFamilyPlusProduct) setIsLoadingCart(true);
            setFamilyPlus(_object_spread_props(_object_spread({}, familyPlus), {
                updateCart: _object_spread_props(_object_spread({}, familyPlus.accept), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isErrorGetShoppingCart) {
            if (product === null || product === void 0 ? void 0 : product.isFamilyPlusProduct) setIsLoadingCart(false);
            setFamilyPlus(_object_spread_props(_object_spread({}, familyPlus), {
                updateCart: _object_spread_props(_object_spread({}, familyPlus.accept), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("shoppingCart", errorGetShoppingCart),
                    errorType: IFamilyPlusErrorType.GET_SHOPPING_CART
                })
            }));
        } else if (isSuccessGetShoppingCart) {
            // if not is familyplus from hub
            // switch product
            if (!(product === null || product === void 0 ? void 0 : product.isFamilyPlusProduct)) {
                var hub = page ? getHubPath(page) : "";
                if (familyPlusProduct) {
                    localStorage.setItem("tProd_backUp", JSON.stringify(product));
                    setProduct(_object_spread_props(_object_spread({}, product, familyPlusProduct, hub && {
                        hubPath: hub
                    }, updateProductPrice(familyPlusProduct)), {
                        shoppingCartUrl: window.location.href
                    }));
                }
            } else {
                // else update family plus price
                setProduct(_object_spread({}, product, updateProductPrice(product)));
                setIsLoadingCart(false);
            }
            setFamilyPlus(_object_spread_props(_object_spread({}, familyPlus), {
                updateCart: _object_spread_props(_object_spread({}, familyPlus.accept), {
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    errorType: undefined
                })
            }));
        }
    }, [
        isLoadingGetShoppingCart,
        isErrorGetShoppingCart,
        isSuccessGetShoppingCart
    ]);
    // endregion
    // region ACCEPT
    useEffect(function() {
        var ref;
        if ((familyPlus === null || familyPlus === void 0 ? void 0 : (ref = familyPlus.accept) === null || ref === void 0 ? void 0 : ref.acceptId) && !isSuccessGetShoppingCart && !isLoadingGetShoppingCart) getShoppingCartRefetch();
    }, [
        familyPlus
    ]);
    useEffect(function() {
        if (isLoadingAcceptCampaign) {
            setFamilyPlus(_object_spread_props(_object_spread({}, familyPlus), {
                accept: _object_spread_props(_object_spread({}, familyPlus.accept), {
                    isLoading: true,
                    isSuccess: false,
                    isAccepted: false,
                    isError: false
                })
            }));
        } else if (isErrorAcceptCampaign) {
            setFamilyPlus(_object_spread_props(_object_spread({}, familyPlus), {
                accept: _object_spread_props(_object_spread({}, familyPlus.accept), {
                    isLoading: false,
                    isSuccess: false,
                    isAccepted: false,
                    isError: true,
                    errorType: IFamilyPlusErrorType.ACCEPT
                })
            }));
        } else if (isSuccessAcceptCampaign) {
            setFamilyPlus(_object_spread_props(_object_spread({}, familyPlus), {
                accept: _object_spread_props(_object_spread({}, familyPlus.accept), {
                    isLoading: false,
                    isSuccess: true,
                    isAccepted: true,
                    isError: false,
                    acceptId: acceptCampaignData === null || acceptCampaignData === void 0 ? void 0 : acceptCampaignData.orderId
                }),
                updateCart: _object_spread_props(_object_spread({}, familyPlus.updateCart), {
                    isLoading: true
                })
            }));
        }
    }, [
        isLoadingAcceptCampaign,
        isErrorAcceptCampaign,
        isSuccessAcceptCampaign
    ]);
    useEffect(function() {
        var ref;
        if (isStartFamilyPlusAccept && !(familyPlus === null || familyPlus === void 0 ? void 0 : (ref = familyPlus.accept) === null || ref === void 0 ? void 0 : ref.isAccepted)) {
            var ref1, ref2;
            setIsStartAcceptFamilyPlus(false);
            mutateAcceptCampaign({
                campaignId: ((ref1 = familyPlus.campaign) === null || ref1 === void 0 ? void 0 : ref1.campaignId) || "",
                sessionId: ((ref2 = familyPlus.campaign) === null || ref2 === void 0 ? void 0 : ref2.sessionId) || ""
            });
        }
    }, [
        isStartFamilyPlusAccept
    ]);
    // endregion
    // region CAMPAIGNS
    var checkHasCampaign = function() {
        var ref, ref1;
        var foundedCampaign = campaignsData === null || campaignsData === void 0 ? void 0 : campaignsData.find(function(item) {
            var ref;
            return (item === null || item === void 0 ? void 0 : (ref = item.productOfferId) === null || ref === void 0 ? void 0 : ref.toString()) === (familyPlusId === null || familyPlusId === void 0 ? void 0 : familyPlusId.toString());
        });
        setFamilyPlus(_object_spread_props(_object_spread({}, familyPlus), {
            campaign: _object_spread(_object_spread_props(_object_spread({}, familyPlus.campaign), {
                isLoading: false,
                isError: false,
                isSuccess: true,
                isEligible: !!foundedCampaign,
                campaignId: (foundedCampaign === null || foundedCampaign === void 0 ? void 0 : (ref = foundedCampaign.id) === null || ref === void 0 ? void 0 : ref.toString()) || "",
                sessionId: (foundedCampaign === null || foundedCampaign === void 0 ? void 0 : (ref1 = foundedCampaign.sessionId) === null || ref1 === void 0 ? void 0 : ref1.toString()) || ""
            }), !!foundedCampaign && {
                familyPlusProduct: familyPlusProduct
            })
        }));
    };
    useEffect(function() {
        if (isLoadingCampaigns) {
            setFamilyPlus(_object_spread_props(_object_spread({}, familyPlus), {
                campaign: _object_spread_props(_object_spread({}, familyPlus.campaign), {
                    isSuccess: false,
                    isError: false,
                    isEligible: false,
                    isLoading: true
                })
            }));
        } else if (isErrorCampaigns) {
            setFamilyPlus(_object_spread_props(_object_spread({}, familyPlus), {
                campaign: _object_spread_props(_object_spread({}, familyPlus.campaign), {
                    isLoading: false,
                    isSuccess: false,
                    isEligible: false,
                    isError: true,
                    errorType: IFamilyPlusErrorType.CAMPAIGNS
                })
            }));
        } else if (isSuccessCampaigns) {
            checkHasCampaign();
        }
    }, [
        isLoadingCampaigns,
        isErrorCampaigns,
        isSuccessCampaigns
    ]);
    useEffect(function() {
        if (!isProspectOrInactive && isStartFamilyPlus) {
            setIsStartFamilyPlus(false);
            refetchCampaignsInfoMobile();
        }
    }, [
        isStartFamilyPlus
    ]);
    // endregion
    return null;
};
