import { ALL_EVENTS, checkBypassApiCallFromSIA, checkIsGoBackSIA, createPayloadFixed, getIsStartFromLockin, isLockInIdServiceable, useDeleteItemShoppingCart, useGetCountry, useShoppingCartMutation } from "@vfit/consumer/data-access";
import { useEffect, useState } from "react";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { getLocalAddress, isAppLockin } from "../shoppingCart.utils";
export function useShoppingCartInfo(product) {
    var isSuccessDynamicCart = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true;
    var ref = useState(true), isLoading = ref[0], setIsLoading = ref[1];
    var ref1 = useState(false), isError = ref1[0], setIsError = ref1[1];
    var ref2 = useState(false), isCompletedSilentLogin = ref2[0], setIsCompletedSilentLogin = ref2[1];
    var countries = getFromLocalStorageByKey("getCountry");
    var ref3 = useGetCountry({
        enabled: false
    }), isCountriesLoading = ref3.isLoading, refetchCountry = ref3.refetch, isSuccessCountry = ref3.isSuccess, isErrorGetCountry = ref3.isError;
    var ref4 = useShoppingCartMutation(), mutateShoppingCart = ref4.mutate, isLoadingShoppingCart = ref4.isLoading, isSuccessShoppingCart = ref4.isSuccess, isErrorShoppingCart = ref4.isError, shoppingCartData = ref4.data;
    var ref5 = useDeleteItemShoppingCart(), mutateDeleteShoppingCart = ref5.mutate;
    var isSuccessGetCountry = isSuccessCountry || !!countries;
    var checkDeleteCart = function() {
        var offeringServiceability = getFromLocalStorageByKey("offeringServiceability");
        if (offeringServiceability) {
            if (isSuccessShoppingCart && shoppingCartData) {
                var ref, ref1, ref2, ref3;
                var tvItemIdFromMutation = shoppingCartData === null || shoppingCartData === void 0 ? void 0 : (ref = shoppingCartData.cartItem) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.tv) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.id;
                if (tvItemIdFromMutation) mutateDeleteShoppingCart(tvItemIdFromMutation);
            }
        }
    };
    var checkShoppingCart = function() {
        var paymentErrorTitle = localStorage.getItem("pymT");
        var paymentErrorMessage = localStorage.getItem("pymM");
        if (isAppLockin() || isLockInIdServiceable()) {
            setIsLoading(!isSuccessGetCountry);
            setIsError(false);
            return;
        }
        var isCallShoppingCart = !checkIsGoBackSIA() && !paymentErrorMessage && !paymentErrorTitle && isSuccessDynamicCart && isCompletedSilentLogin && !isLoadingShoppingCart && !isSuccessShoppingCart && isSuccessGetCountry;
        if (isCallShoppingCart) {
            var ref, ref1, ref2, ref3, ref4;
            var offeringServiceability = getFromLocalStorageByKey("offeringServiceability");
            var createdAddress = getLocalAddress();
            var payload = createPayloadFixed(product === null || product === void 0 ? void 0 : (ref = product.offerId) === null || ref === void 0 ? void 0 : ref.toString(), ((ref1 = createdAddress) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.id) === null || ref2 === void 0 ? void 0 : ref2.toString()) || "", ((ref3 = offeringServiceability) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.id) === null || ref4 === void 0 ? void 0 : ref4.toString()) || "");
            if (payload) mutateShoppingCart(payload);
            else {
                setIsError(true);
            }
        }
    };
    useEffect(function() {
        if (isErrorShoppingCart || isErrorGetCountry) {
            setIsError(true);
            setIsLoading(false);
        }
    }, [
        isErrorShoppingCart,
        isErrorGetCountry
    ]);
    useEffect(function() {
        if (isSuccessShoppingCart && !isCountriesLoading) {
            checkDeleteCart();
            setIsLoading(false);
        }
    }, [
        isSuccessShoppingCart,
        isCountriesLoading
    ]);
    useEffect(function() {
        checkShoppingCart();
    }, [
        isCompletedSilentLogin,
        isSuccessGetCountry,
        isSuccessDynamicCart
    ]);
    useEffect(function() {
        var listener = function() {
            setIsCompletedSilentLogin(true);
        };
        if (!checkBypassApiCallFromSIA()) {
            if (!countries) refetchCountry();
        }
        if (getIsStartFromLockin() && !isLockInIdServiceable()) listener();
        if (typeof document !== "undefined") {
            document.addEventListener(ALL_EVENTS.SILENT_LOGIN_COMPLETE, listener);
        }
        return function() {
            document.removeEventListener(ALL_EVENTS.SILENT_LOGIN_COMPLETE, listener);
        };
    }, []);
    return {
        isCompletedSilentLogin: isCompletedSilentLogin,
        isLoading: isLoading,
        isError: isError
    };
}
