import { InvoiceType } from "./chooseInvoice.models";
export var organizeBillingInvoiceCard = function(billingCheckboxProps, address, owningIndividual, ecoIcon) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11;
    var prefix = billingCheckboxProps === null || billingCheckboxProps === void 0 ? void 0 : (ref = billingCheckboxProps.billinginformation) === null || ref === void 0 ? void 0 : ref.card;
    return {
        title: (prefix === null || prefix === void 0 ? void 0 : prefix.title) || "",
        description: (prefix === null || prefix === void 0 ? void 0 : prefix.description) || "",
        items: [
            {
                title: (prefix === null || prefix === void 0 ? void 0 : (ref1 = prefix.choice) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.option1) === null || ref2 === void 0 ? void 0 : ref2.title) || "",
                description: (prefix === null || prefix === void 0 ? void 0 : (ref3 = prefix.choice) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.option1) === null || ref4 === void 0 ? void 0 : ref4.description.replace("FIRSTADDRESS", "".concat(address))) || "",
                extra: {
                    text: ""
                },
                value: InvoiceType === null || InvoiceType === void 0 ? void 0 : InvoiceType.PAPER
            },
            {
                title: (prefix === null || prefix === void 0 ? void 0 : (ref5 = prefix.choice) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.option2) === null || ref6 === void 0 ? void 0 : ref6.title) || "",
                description: (prefix === null || prefix === void 0 ? void 0 : (ref7 = prefix.choice) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.option2) === null || ref8 === void 0 ? void 0 : ref8.description.replace("EMAIL", "".concat((owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.contactMedium) && ((ref9 = owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.contactMedium[0]) === null || ref9 === void 0 ? void 0 : ref9.emailAddress)))) || "",
                extra: {
                    text: (prefix === null || prefix === void 0 ? void 0 : (ref10 = prefix.choice) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.option2) === null || ref11 === void 0 ? void 0 : ref11.linkEcoLabel) || "",
                    color: "#008a00",
                    icon: ecoIcon
                },
                value: InvoiceType === null || InvoiceType === void 0 ? void 0 : InvoiceType.EBILL
            }, 
        ]
    };
};
