import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { checkBypassApiCallFromSIA, checkIsGoBackSIA, errorMock, useGetCustomer, useRetrieveMultiLines } from "@vfit/consumer/data-access";
import { useEffect } from "react";
import { useCheckout } from "../../../iBuyMobile.context";
import { useTermsFlow } from "../UseTermsFlow/useTermsFlow";
export var useCustomerAsyncInformation = function() {
    var ref = useCheckout(), termsAndConditions = ref.termsAndConditions, customerId = ref.customerId, simTypeSelectedOption = ref.simTypeSelectedOption, isStartRecognition = ref.isStartRecognition, customerAsyncInfo = ref.customerAsyncInfo, setIsStartRecognition = ref.setIsStartRecognition, setTermsAndConditions = ref.setTermsAndConditions, setCustomerAsyncInfo = ref.setCustomerAsyncInfo;
    var ref1 = useTermsFlow(termsAndConditions, checkBypassApiCallFromSIA(), true, !!customerId), isSuccessTermsAndConditions = ref1.isSuccess, isErrorTermsAndConditions = ref1.isError, isLoadingTermsAndConditions = ref1.isLoading;
    var ref2 = useRetrieveMultiLines(), retrieveMultilinesError = ref2.error, isSuccessRetrieveMultilines = ref2.isSuccess, isErrorRetrieveMultilines = ref2.isError, isLoadingRetrieveMultilines = ref2.isLoading, retrieveMultilinesMutation = ref2.mutate;
    var ref3 = useGetCustomer(customerId, {
        enabled: false
    }), customerInfoData = ref3.data, isSuccessCustomerInfo = ref3.isSuccess, isLoadingCustomerInfo = ref3.isLoading, isErrorCustomerInfo = ref3.isError, customerInfoError = ref3.error, refetchCustomerInfo = ref3.refetch;
    useEffect(function() {
        if (isStartRecognition) setIsStartRecognition(false);
    }, [
        isStartRecognition
    ]);
    useEffect(function() {
        if (isLoadingCustomerInfo) {
            setCustomerAsyncInfo(_object_spread_props(_object_spread({}, customerAsyncInfo), {
                customerInfo: _object_spread_props(_object_spread({}, customerAsyncInfo.customerInfo), {
                    error: undefined,
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isErrorCustomerInfo) {
            setCustomerAsyncInfo(_object_spread_props(_object_spread({}, customerAsyncInfo), {
                customerInfo: _object_spread_props(_object_spread({}, customerAsyncInfo.customerInfo), {
                    isSuccess: false,
                    isLoading: false,
                    isError: true,
                    error: errorMock("customerInfo", customerInfoError)
                })
            }));
        } else if (isSuccessCustomerInfo) {
            setCustomerAsyncInfo(_object_spread_props(_object_spread({}, customerAsyncInfo), {
                customerInfo: _object_spread_props(_object_spread({}, customerAsyncInfo.customerInfo), {
                    error: undefined,
                    data: customerInfoData,
                    isLoading: false,
                    isError: false,
                    isSuccess: true
                })
            }));
        }
    }, [
        isErrorCustomerInfo,
        isLoadingCustomerInfo,
        isSuccessCustomerInfo
    ]);
    useEffect(function() {
        if (isLoadingRetrieveMultilines) {
            setCustomerAsyncInfo(_object_spread_props(_object_spread({}, customerAsyncInfo), {
                retrieveMultilines: _object_spread_props(_object_spread({}, customerAsyncInfo.retrieveMultilines), {
                    error: undefined,
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isErrorRetrieveMultilines) {
            setCustomerAsyncInfo(_object_spread_props(_object_spread({}, customerAsyncInfo), {
                retrieveMultilines: _object_spread_props(_object_spread({}, customerAsyncInfo.retrieveMultilines), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("retrieveMultilines", retrieveMultilinesError)
                })
            }));
        } else if (isSuccessRetrieveMultilines) {
            setCustomerAsyncInfo(_object_spread_props(_object_spread({}, customerAsyncInfo), {
                retrieveMultilines: _object_spread_props(_object_spread({}, customerAsyncInfo.retrieveMultilines), {
                    error: undefined,
                    isLoading: false,
                    isError: false,
                    isSuccess: true
                })
            }));
        }
    }, [
        isErrorRetrieveMultilines,
        isLoadingRetrieveMultilines,
        isSuccessRetrieveMultilines
    ]);
    useEffect(function() {
        if (isLoadingTermsAndConditions) {
            setCustomerAsyncInfo(_object_spread_props(_object_spread({}, customerAsyncInfo), {
                termsAndConditions: _object_spread_props(_object_spread({}, customerAsyncInfo.termsAndConditions), {
                    error: undefined,
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isErrorTermsAndConditions) {
            setCustomerAsyncInfo(_object_spread_props(_object_spread({}, customerAsyncInfo), {
                termsAndConditions: _object_spread_props(_object_spread({}, customerAsyncInfo.termsAndConditions), {
                    isSuccess: false,
                    isError: true,
                    isLoading: false
                })
            }));
        } else if (isSuccessTermsAndConditions) {
            setTermsAndConditions(_object_spread_props(_object_spread({}, termsAndConditions), {
                isSuccessTermsAndConditions: isSuccessTermsAndConditions,
                isLoadingTermsAndConditions: isLoadingTermsAndConditions,
                isErrorTermsAndConditions: isErrorTermsAndConditions
            }));
            setCustomerAsyncInfo(_object_spread_props(_object_spread({}, customerAsyncInfo), {
                termsAndConditions: _object_spread_props(_object_spread({}, customerAsyncInfo.termsAndConditions), {
                    error: undefined,
                    isError: false,
                    isSuccess: true,
                    isLoading: false
                })
            }));
        }
    }, [
        isErrorTermsAndConditions,
        isLoadingTermsAndConditions,
        isSuccessTermsAndConditions
    ]);
    useEffect(function() {
        if (customerId && !checkIsGoBackSIA()) {
            refetchCustomerInfo();
            retrieveMultilinesMutation();
            if (simTypeSelectedOption) setIsStartRecognition(true);
        }
    }, [
        customerId
    ]);
    return null;
};
