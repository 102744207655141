import { useEffect, useState } from "react";
import { ErrorCodes, useCheckPortInFeasibilityIIMobile, useModifyPhoneNumberMobileProduct } from "@vfit/consumer/data-access";
export var usePortabilityFlow = function(portability) {
    var flowEnabler = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    var ref = useState(false), isSuccess = ref[0], setIsSuccess = ref[1];
    var ref1 = useState(false), isLoading = ref1[0], setIsLoading = ref1[1];
    var ref2 = useState(false), isError = ref2[0], setIsError = ref2[1];
    var ref3 = useState(""), errorMessage = ref3[0], setErrorMessage = ref3[1];
    var ref4 = useCheckPortInFeasibilityIIMobile(), mutatePortIn = ref4.mutate, dataPortIn = ref4.data, isErrorPortIn = ref4.isError, isLoadingPortIn = ref4.isLoading, isSuccessPortIn = ref4.isSuccess, resetPortIn = ref4.reset;
    var ref5 = useModifyPhoneNumberMobileProduct(), mutateModify = ref5.mutate, isErrorModify = ref5.isError, isSuccessModify = ref5.isSuccess;
    useEffect(function() {
        if (flowEnabler) {
            setIsLoading(true);
            mutatePortIn(portability);
        }
    }, [
        flowEnabler
    ]);
    useEffect(function() {
        var ref, ref1, ref2, ref3, ref4;
        if (isSuccessPortIn && dataPortIn && !isErrorPortIn && ((ref = dataPortIn.resultDescription) === null || ref === void 0 ? void 0 : ref.toLowerCase()) !== "failure" && !((ref1 = dataPortIn) === null || ref1 === void 0 ? void 0 : ref1.error)) {
            mutateModify(portability);
        }
        if ((ref2 = dataPortIn) === null || ref2 === void 0 ? void 0 : ref2.error) {
            var ref5;
            setIsLoading(false);
            setIsError(true);
            setErrorMessage((ref5 = dataPortIn) === null || ref5 === void 0 ? void 0 : ref5.errorMessage);
        }
        if (isSuccessPortIn && (!dataPortIn || ((ref3 = dataPortIn) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.resultDescription) === null || ref4 === void 0 ? void 0 : ref4.toLowerCase()) === "failure")) {
            var ref6, ref7, ref8, ref9, ref10, ref11;
            resetPortIn();
            setIsLoading(false);
            setIsSuccess(false);
            setIsError(true);
            var errorText = "";
            if (((ref6 = dataPortIn) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.resultMessages) === null || ref7 === void 0 ? void 0 : (ref8 = ref7[0]) === null || ref8 === void 0 ? void 0 : ref8.messageCode) === "9000054") {
                errorText = ErrorCodes.MIGRATION_CODE_ERROR;
            } else if ((ref9 = dataPortIn) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.resultMessages) === null || ref10 === void 0 ? void 0 : (ref11 = ref10[0]) === null || ref11 === void 0 ? void 0 : ref11.messageDescription) {
                errorText = ErrorCodes.NUMBER_ERROR;
            } else {
                // not mapped error on checkPortInFeasibility if "resultDescription": "Failure"
                errorText = ErrorCodes.CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR;
            }
            setErrorMessage(errorText);
        }
    }, [
        dataPortIn,
        isLoadingPortIn,
        isSuccessPortIn,
        isErrorPortIn
    ]);
    useEffect(function() {
        if (!isError && isSuccessModify) {
            setIsLoading(false);
            setIsSuccess(true);
        }
        if (isErrorModify) {
            setIsLoading(false);
            setIsError(true);
        }
    }, [
        isError,
        isSuccessModify,
        isErrorModify
    ]);
    return {
        isSuccess: isSuccess,
        isLoading: isLoading,
        isError: isError,
        errorMessage: errorMessage
    };
};
