export var ID_FLOWS = {
    PERSONAL_INFO: "Personal info",
    PERSONAL_INFO_WINBACK: "Personal Info Winback",
    DOCUMENT_ID: "Document id",
    CONTACT_CARD: "Contact Card",
    CONFIRM_IDENDITY: "Confirm Identity",
    OTP: "Otp",
    WINBACK_OTP: "Winback otp",
    PAYMENT_METHOD: "Payment method",
    RECURRENT_PAYMENT_CARD: "Recurrent payment card",
    PORTABILITY: "Portability",
    PORTABILITY_NUMBER: "Portability Number",
    PORTABILITY_NUMBER_WINBACK: "Portability Number Winback",
    PORTABILITY_NUMBER_PORTABILITY_REQUIRED: "Portability Number portability required",
    WINBACK_NUMBER: "Winback Number",
    DELIVERY_ADDRESS: "Delivery",
    PORTABILITY_SIM_SERIAL: "Portability Sim Serial",
    PORTABILITY_OTP: "Portability Otp",
    PORTABILITY_DATA: "Portability Data",
    ESIM_SIM_SELECTION: "ESimSIM Selection",
    RECOGNITION_ESIM: "ESim recognition",
    RECOGNITION_ESIM_WINBACK: "ESim Recognition Winback",
    RECOGNITION_CARD: "EsimSim recognition",
    RECOGNITION_SIM_METHOD_SELECTION: "Sim recognition method selection",
    RECOGNITION_SIM_COURIER: "Sim recognition courier",
    RECOGNITION_SIM_VIDEO_RECOGNITION: "Sim recognition video recognition",
    TERMS_AND_CONDITIONS: "Terms and Conditions",
    CONSENTS: "Consents",
    WARNING_PRICE_CHANGE: "Warning price change",
    UPFRONT_PAYMENT: "Upfront payment",
    VOUCHER: "Voucher"
};
export var DEFAULT_CURRENT_STEP_KEY = ID_FLOWS.PERSONAL_INFO;
