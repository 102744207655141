import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 32px 16px 60px;\n\n  @media (min-width: ",
        ") {\n    padding: 60px 40px 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 60px 55px 64px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-weight: 400;\n  color: ",
        ";\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  text-align: center;\n  max-width: 287px;\n  font-family: ",
        ";\n  ",
        "\n  margin-top: 16px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    max-width: 366px;\n  }\n\n  @media (min-width: ",
        ") {\n    max-width: 406px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin-top: 32px;\n  margin-bottom: 8px;\n  text-align: start;\n  font-family: ",
        ";\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 16px;\n    margin-top: 33px;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 40px;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  .username {\n    text-transform: capitalize;\n  }\n  p {\n    margin: 0;\n    font-weight: 400;\n    color: ",
        ";\n    text-align: start;\n    font-family: ",
        ";\n    ",
        "\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  }\n\n  p:nth-child(2) {\n    font-weight: 700;\n    color: ",
        ";\n  }\n\n  p:nth-child(5) {\n    margin-top: ",
        ";\n\n    @media (min-width: ",
        ") {\n      margin-top: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  background-color: transparent;\n  padding: 0;\n  margin: 0;\n  border: none;\n  border-bottom: 1px solid ",
        ";\n  margin-top: 8px;\n  color: ",
        ";\n  font-weight: 700;\n  font-family: ",
        ";\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-top: 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  &:hover {\n    cursor: pointer;\n    color: ",
        ";\n    border-bottom: 1px solid ",
        ";\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  margin-top: 24px;\n  border-bottom: 1px solid ",
        ";\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  max-width: 287px;\n  margin-top: 20px;\n  margin-bottom: 16px;\n  color: #333;\n\n  @media (min-width: ",
        ") {\n    max-width: 366px;\n    margin-top: 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    max-width: 406px;\n    margin-top: 25px;\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  font-weight: 700;\n  color: #333;\n  text-align: start;\n  font-family: ",
        ";\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  margin-top: 8px;\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  width: inherit;\n  background: linear-gradient(0.25turn, transparent, ",
        ", transparent),\n    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);\n  background-repeat: no-repeat;\n  animation: loading 1.5s infinite;\n\n  :first-child {\n    margin-top: 60px;\n    margin-bottom: 16px;\n    height: 45px;\n  }\n\n  height: 150px;\n  margin-top: 32px;\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n    margin-left: 0;\n    margin-right: 0;\n  }\n\n  @keyframes loading {\n    to {\n      background-position: 315px 0, 0 0, 0 190px, 50px 195px;\n    }\n  }\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  height: 30px;\n  margin-top: 32px;\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n    margin-left: 0;\n    margin-right: 0;\n  }\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-de55bcc9-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var TopContent = styled.div.withConfig({
    componentId: "sc-de55bcc9-1"
})(_templateObject1());
export var Paragraph = styled.p.withConfig({
    componentId: "sc-de55bcc9-2"
})(_templateObject2(), colors.$262626);
export var Title = styled(Paragraph).withConfig({
    componentId: "sc-de55bcc9-3"
})(_templateObject3(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Description = styled(Paragraph).withConfig({
    componentId: "sc-de55bcc9-4"
})(_templateObject4(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30), breakpoints.desktop);
export var InstallationAddress = styled(Paragraph).withConfig({
    componentId: "sc-de55bcc9-5"
})(_templateObject5(), fonts.exbold, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(24, 30), breakpoints.desktop);
export var UserInfo = styled.div.withConfig({
    componentId: "sc-de55bcc9-6"
})(_templateObject6(), colors.$262626, fonts.regular, pxToCssFont(18, 24), breakpoints.tablet, pxToCssFont(20, 30), colors.$e60000, pxToRem(8), breakpoints.tablet, pxToRem(16));
export var DeliveryCta = styled.button.withConfig({
    componentId: "sc-de55bcc9-7"
})(_templateObject7(), colors.$262626, colors.$262626, fonts.regular, pxToCssFont(16, 22), breakpoints.tablet, pxToCssFont(18, 22), breakpoints.desktop, pxToCssFont(18, 26), colors.$e60000, colors.$e60000);
export var Divider = styled.div.withConfig({
    componentId: "sc-de55bcc9-8"
})(_templateObject8(), colors.$bebebe);
export var BottomTitle = styled(InstallationAddress).withConfig({
    componentId: "sc-de55bcc9-9"
})(_templateObject9(), breakpoints.tablet, breakpoints.desktop);
export var LabelCta = styled(Paragraph).withConfig({
    componentId: "sc-de55bcc9-10"
})(_templateObject10(), fonts.regular, pxToCssFont(16, 22), breakpoints.tablet, pxToCssFont(18, 24));
export var BillingCta = styled(DeliveryCta).withConfig({
    componentId: "sc-de55bcc9-11"
})(_templateObject11());
export var TextLoader = styled.div.withConfig({
    componentId: "sc-de55bcc9-12"
})(_templateObject12(), colors.$ffffff, breakpoints.tablet);
export var TextLoaderEmpty = styled.div.withConfig({
    componentId: "sc-de55bcc9-13"
})(_templateObject13(), breakpoints.tablet);
